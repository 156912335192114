export const hero = {
  title: "Maximize your restaurant profit margins",
  subtext:
    "We build technology designed to increase revenue and lower costs, so you can go from the 5-7% profit margin that you’ve come to expect to the double digit restaurant margins you deserve. Doesn’t seem possible? Use our restaurant profit calculator to see how technology can help your restaurant.",
  ctaPrimary: {
    title: "Calculate your revenue potential",
    target: "/profit-margin-calculator",
  },
};

export const subHero = {
  title: "Restaurant profit playbook",
  list: [
    `How an <a href="https://www.spoton.com/solutions/online-ordering-and-delivery/" target="_blank">online ordering system</a> can save up to <b>30%</b> in revenue`,
    `How <a href="https://www.spoton.com/solutions/online-ordering-and-delivery/" target="_blank">QR codes</a> help increase staff and server tips by <b>6.5%</b>`,
    `How <a href="https://www.spoton.com/restaurant-pos/handheld-pos/" target="_blank">handhelds</a> increase the average tables served by <b>4.2%</b>`,
    `How a <a href="https://www.spoton.com/restaurant-pos/kitchen-display-system/" target="_blank">kitchen display system</a> can increase monthly profit by <b>$2k</b>`,
    `How a <a href="https://www.spoton.com/solutions/loyalty/" target="_blank">loyalty program</a> can help <b>40%</b> of guests to spend more`,
  ],
  subtext:
    'Running a restaurant is hard work, so it can be discouraging when you see how little of that work shows up in your bottom line. In our playbook "The No BS Guide to Restaurant Profit" we show how you can improve your restaurant profit margins by implementing flexible technology. Because hard work can’t cut the fees from third-party delivery apps or get orders in and out of the kitchen to your guests faster. But technology can.',
  PrimaryCta: {
    ctaTitle: "Get the free playbook",
    // ctaTarget: "https://www.spoton.com/Maximizing-Profits.pdf",
    ctaTarget: "https://www.spoton.com/gated-assets/restaurant-profit-guide/",
  },
};

export const productsWithLinks = {
  title: `Get the profit margins guide for your restaurant type`,
  products: [
    {
      title: "Fine dining",
      linkTo: "https://spoton.com/blog/restaurant-profit-margins-fine-dining/",
      imageName: "fine-dining-card.png",
    },
    {
      title: "Family & casual dining",
      linkTo:
        "https://spoton.com/blog/restaurant-profit-margins-casual-dining/",
      imageName: "family-casual-dining-card.png",
    },
    {
      title: "Fast casual",
      linkTo: "https://spoton.com/blog/restaurant-profit-margins-fast-casual/",
      imageName: "fast-casual-card.png",
    },
    {
      title: "Quick service",
      linkTo:
        "https://spoton.com/blog/restaurant-profit-margins-quick-service/",
      imageName: "quick-service-card.png",
    },
  ],
};

export const videoData = {
  title: "What's your point of profit",
  subtext:
    "You built your business on hard work, integrity, and treating customers with respect. You deserve a partner who shares those values. We built SpotOn to empower business owners like you to run your business more efficiently, reach more customers, and realize your dreams. Whether you run a cafe, clothing boutique, or a concert venue, we've got you covered.",
  videoUrl: "kFpC6wLKu2s",
  videoPoster: "points_of_profit.png",
};

export const quotes = [
  {
    imageName: "hillary-holmes.png",
    name: "Hillary Holmes",
    title:
      "General Manager, Von Elrod’s Beer Hall and Kitchen, Nashville, Tennessee",
    quote: `“SpotOn’s QR code technology allows guests to get a second or third beer without a long wait time or to get up to go somewhere to get it. We were typically seeing between $30 – $32 per guest average, and now we’re seeing $40 – $50 per guest average. We owe that to those additional beverages hitting those guest checks.”`,
  },
  {
    imageName: "michael-mina.png",
    name: "Michael Mina",
    title: "Chef/Owner, Mina Restaurant Group",
    quote: `“We know firsthand, it's not just the staffing that's becoming hard, but it's becoming more challenging to stay compliant with all the labor laws and regulations. With SpotOn's labor integrations and acquisition, they're staying true to tackling our industry's biggest challenges.” `,
  },
  {
    imageName: "matthew-kenney.png",
    name: "Matthew Kenney",
    title: "CEO of Matthew Kenney Cuisine",
    quote: `“Having access to data and reporting in easy-to-use formats is always important in a business that has such slim margins. However it’s critically important in a company like ours that’s involved in 45 – 50 different venues in multiple cities around the world…SpotOn is the only tech partner who gives us the ability to look at our entire brand as a whole, and have consistency in resources and reporting across our business areas.” `,
  },
];

export const footer = {
  title: "Book your consultation",
  subtext:
    "Talk to one of our restaurant technology experts and learn how you can improve your restaurant profits.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/demo",
  },
};
