import React from "react";
import loadable from "@loadable/component";
import { isMobile } from "react-device-detect";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import {
  hero,
  videoData,
  footer,
} from "../../data/whitepapers/restaurants-hub";
import Title from "../../components-v2/Base/Title";
import bg from "../../images/calculator/background.png";
import bgMobile from "../../images/calculator/background-mobile.png";

const Products = loadable(() =>
  import("../../page-components/points-of-profit/products")
);
const Hero = loadable(() =>
  import("../../page-components/points-of-profit/hero")
);
const NoVisuals = loadable(() =>
  import("../../page-components/points-of-profit/no-visuals")
);
const SimpleHero = loadable(() =>
  import("../../components-v2/Sections/Hero/SimpleHero")
);
const YoutubeVideo = loadable(() => import("../../components-v2/YoutubeVideo"));
const Quotes = loadable(() =>
  import("../../page-components/points-of-profit/quotes")
);
const FooterHero = loadable(() =>
  import("../../components-v2/Sections/Hero/FooterHero")
);
// const CapterraBanner = loadable(() =>
//   import("../../components-v2/CapterraBanner")
// );

const PointsOfProfit = () => {
  return (
    <Layout className="non-fixed-width a-ec" transparentHeader={false}>
      <SEO
        title="Maximizing Profits | Restaurant Profit Margins | SpotOn"
        description="Learn how to increase your restaurant profit margins to the double digits that you deserve."
      />

      <section className="w-full bg-black mx-auto pb-40 md:pb-60 lg:pb-96 relative">
        {/* <div */}
        {/*  className={` */}
        {/*  w-[590px] h-[590px] absolute right-0 -top-20 */}
        {/*  bg-[#055EFF] opacity-70 rounded-full blur-[416px] */}
        {/* `} */}
        {/* /> */}

        <img
          className="absolute -top-10 right-0 z-0"
          src={isMobile ? bgMobile : bg}
          alt="background"
        />

        <SimpleHero
          className="mt-32 lg:mt-40 mx-auto"
          customHeadingSize="md:max-w-70-percent lg:max-w-90-percent xl:max-w-70-percent"
          {...hero}
          titleCustomClass="text-white"
        />
      </section>

      <Hero />

      <NoVisuals />

      <Products />

      <section className="max-w-1120 w-full mt-20 lg:mt-28">
        <Title level={3} withBlueSymbol symbol="?" className="text-center">
          {videoData.title}
        </Title>
        <YoutubeVideo className="" {...videoData} />
      </section>

      <Quotes />

      {/* <CapterraBanner
        className="mt-16 lg:mt-20 mb-20 lg:mb-40"
        imageName="capterra-pos.png"
        title={`How restaurant operators rate SpotOn<span class="text-primary">.</span>`}
        withCta
        ctaLabel="See all the comparisons"
        ctaTarget="/restaurant-pos/capterra-competive-comparison/"
      /> */}

      <FooterHero
        className="mt-20 lg:mt-36 mb-12 lg:mb-20"
        {...footer}
        variant={2}
      />
    </Layout>
  );
};

export default PointsOfProfit;
